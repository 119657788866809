import React from "react";
import { Button, Col, Container, Row } from "reactstrap";
import pooja from "../../../../assets/images/1_pooja.png";
import yagna from "../../../../assets/images/2_homam_yagna.png";
import astrology from "../../../../assets/images/3_astrology.png";
import vastu from "../../../../assets/images/4_vastu-compass.png";
import temple from "../../../../assets/images/5_hindu-temple.png";
import marriage from "../../../../assets/images/6_marriage.png";
import omLogo from "../../../../assets/images/om-logo.png";

const Services = () => {
  const cardContaint = [
    {
      title: "पूजा",
      icon: pooja,
      desc: "हिन्दू धर्म में पूजा एक महत्वपूर्ण अभ्यास है। पूजा ईश्वर या देवताओं के प्रति भक्ति, समर्पण और आदर्शों का प्रकटीकरण करने का एक आध्यात्मिक और सामाजिक अभ्यास है। इसके माध्यम से, हिन्दू व्यक्ति अपने मन, शरीर और आत्मा को पवित्र और आध्यात्मिक सांस्कृतिक भूमिका में जीने का अनुभव करता है।",
    },
    {
      title: "यज्ञ",
      icon: yagna,
      desc: "हिन्दू धर्म में यज्ञ एक प्रमुख आध्यात्मिक और सामाजिक अनुष्ठान है। यज्ञ धार्मिक कार्यों का एक प्रमुख रूप है जिसका महत्व श्रुति (वेद) में बताया गया है। यह एक आध्यात्मिक अभ्यास है जो ईश्वर के साथ संबंध स्थापित करने, आनंदित और सामर्थिक जीवन जीने में मदद करता है।",
    },
    {
      title: "ज्योतिष",
      icon: astrology,
      desc: "ज्योतिष संस्कृत शब्द है जिसका अर्थ 'ज्योति' और 'ईष' से मिलकर बना है, जो 'प्रकाश' और 'ईश्वर' को दर्शाता है। ज्योतिष के अनुसार, ब्रह्मांड में सभी वस्तुओं के बीच एक संबंध होता है और इसका प्रतिबिंब मानव शरीर पर दिखाई देता है। ज्योतिष विज्ञान के अनुसार, ग्रहों की चाल, स्थान और गुणकारी गुणों का व्यक्ति के जीवन पर प्रभाव पड़ता है। ज्योतिष में व्यक्ति की जन्म कुंडली (भूमंडली) का महत्वपूर्ण स्थान होता है, जिसमें ग्रहों की स्थिति, नक्षत्रों का प्रभाव, राशियों का संयोग और योग आदि के आधार पर भविष्यवाणी की जाती है।",
    },
    {
      title: "वास्तु",
      icon: vastu,
      desc: "हिन्दू धर्म में वास्तु (Vastu) एक महत्वपूर्ण विज्ञान है जो घरों, मंदिरों, और अन्य भवनों के निर्माण और अवयवों के प्राकृतिक आयामों के बीच संबंध को समझता है। वास्तुशास्त्र हिन्दू धर्म की प्राचीनतम शास्त्रों में से एक मानी जाती है और इसे घरेलू और धार्मिक कार्यों के लिए महत्वपूर्ण माना जाता है।",
    },
    {
      title: "मंदिर दर्शन",
      icon: temple,
      desc: "हिन्दू धर्म में मंदिर दर्शन महत्वपूर्ण है। मंदिर धर्मिक स्थान होते हैं जहां हिन्दू भक्त अपने ईश्वर को प्रार्थना, वंदना और भक्ति करते हैं। इसे हिन्दू धर्म का आधारीभूत तत्त्व माना जाता है। मंदिर में आराधना का माध्यम मूर्ति पूजा होती है, जिसमें देवताओं की प्रतिमाएं स्थापित की जाती हैं। भक्त उनके सामर्थ्य, स्वरूप और दिव्यता का अनुभव करते हैं और उनके सामीप आकर अपनी प्रार्थनाएं पेश करते हैं। मंदिरों में धार्मिक कार्यक्रम, आरती, भजन, संस्कृतिक कार्यक्रम और संगठनों की गतिविधियां भी आयोजित की जाती हैं।",
    },
    {
      title: "शादी",
      icon: marriage,
      desc: "हिन्दू धर्म में शादी, जिसे विवाह (Vivah) भी कहा जाता है, एक महत्वपूर्ण सामाजिक और धार्मिक आयोजन है। शादी हिन्दू समाज में परम्परागत रूप से मान्यता प्राप्त है और इसे धार्मिक और सामाजिक बंधन की स्थापना का महत्वपूर्ण अवसर माना जाता है। हिन्दू शादी में कई रीति-रिवाज और परंपराएं होती हैं, जो विभिन्न क्षेत्रों और समुदायों के अनुसार अलग-अलग हो सकती हैं। ",
    },
  ];
  const logoStyle = {
    fontSize: "2rem",
    listStyleType: "none",
    padding: "14px 25px",
    borderBottom: "7px solid #ffd2a4",
    background: "#fff5eb",
    color: "#FF7722",
  };
  const omLogoStyle = {
    width: "38px",
    height: "38px",
    verticalAlign: "top",
  };
  const handleButtonClick = () => {
    const targetDiv = document.getElementById("Booking");
    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <Container className="pt-5" id="Services">
      <h1 className="text-center fw-bolder mb-5">
        <label style={logoStyle}>
          ॐ नमः शिवाय <img src={omLogo} style={omLogoStyle} alt="" /> शिवाय नमः
          ॐ
        </label>
      </h1>
      <p className="text-center fw-bold text-orange">हमारी सेवाएँ</p>
      <Row>
        {cardContaint.map((content) => (
          <Col sm={12} md={4} lg={4} key={content.title}>
            <div className="service-box">
              <span className="img-ser">
                <img src={content.icon} alt="" />
              </span>
              <p className="fw-bolder" style={{ fontSize: "21px" }}>
                {content.title}
              </p>
              <p>{content.desc}</p>
              <Button color="link" className="p-0 text-decoration-none">
                <span className="read-more">और पढ़ें...</span>
              </Button>
              <Button color="link" className="p-0 text-decoration-none">
                <span className="bookbutton" onClick={handleButtonClick}>
                अभी बुक करें
                </span>
              </Button>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Services;
