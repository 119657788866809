import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useMediaQuery } from "react-responsive";
import {
  Button,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import pooja from "../../../../assets/images/1_pooja.png";
import yagna from "../../../../assets/images/2_homam_yagna.png";
import astrology from "../../../../assets/images/3_astrology.png";
import vastu from "../../../../assets/images/4_vastu-compass.png";
import temple from "../../../../assets/images/5_hindu-temple.png";
import marriage from "../../../../assets/images/6_marriage.png";
import ev1 from "../../../../assets/images/ev-1.png";
import iconImage from "../../../../assets/images/om-logo.png";
import qrCode from "../../../../assets/images/qr-code.png";
import rupeePng from "../../../../assets/images/indian-rupee.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

const serviceItems = [
  {
    title: "POOJA",
    icon: pooja,
  },
  {
    title: "HOMAM/YAGNA",
    icon: yagna,
  },
  {
    title: "ASTROLOGY",
    icon: astrology,
  },
  {
    title: "VASTU",
    icon: vastu,
  },
  {
    title: "MANDIR DARSHAN",
    icon: temple,
  },
  {
    title: "MARRIAGE",
    icon: marriage,
  },
];

const serviceTypeItems = [
  {
    title: "भातपूजन",
    priceLabel: "₹2600",
    price: 2600,
  },
  {
    title: "ग्रहशांति एवम कुंभविवाह(अर्कविवाह सहित)",
    priceLabel: "₹4000 + ₹300(रसीद)",
    price: 4800,
  },
  {
    title: "पूर्ण गृहशांति विधान",
    priceLabel: "₹5600",
    price: 5600,
  },
  {
    title: "कालसर्प योग शांति विधान",
    priceLabel: "₹4000",
    price: 4000,
  },
  {
    title: "कालसर्प योग शांति 3 दिवसीय विधान",
    priceLabel: "₹15500 + ₹5600(रुद्राभिषेक सहित)",
    price: 2600,
  },
  {
    title: "कालसर्प योग शांति 3 दिवसीय विधान(रुद्राभिषेक सहित)",
    priceLabel: "₹15500 + ₹5600",
    price: 20600,
  },
  {
    title: "राहु/केतु के जप सहित",
    priceLabel: "₹15500",
    price: 15500,
  },
  {
    title: "रुद्राभिषेक पूजन",
    priceLabel: "₹5600",
    price: 5600,
  },
  {
    title: "महामृत्युंजय जप",
    priceLabel: "₹31500",
    price: 31500,
  },
  {
    title: "पितृशांति पूजन विधान(1दिन)",
    priceLabel: "₹7500",
    price: 7500,
  },
  {
    title: "पितृशान्ति(3दिन)",
    priceLabel: "₹11500",
    price: 11500,
  },
  {
    title: "गृहवास्तु शांति(1 दिवसीय सामग्री सहित)",
    priceLabel: "₹11500",
    price: 11500,
  },
  {
    title: "गृहण योग शांति पूजन ",
    priceLabel: "₹4000",
    price: 4000,
  },
  {
    title: "नवचंडी(3 दिवसीय विधान)",
    priceLabel: "₹25500",
    price: 25500,
  },
  {
    title: "संपुतित नवचंडी विधान",
    priceLabel: "₹31500",
    price: 31500,
  },
  {
    title: "नवग्रह शांति विधान पूजन जप सहित",
    priceLabel: "₹21500",
    price: 21500,
  },
];

const ServiceDropdown = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleItemClick = (item, icon) => {
    setSelectedItem(item);
  };

  return (
    <FormGroup>
      <Label for="datepicker">
        <b>सेवा का चयन करें</b>
      </Label>
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggleDropdown}
        className="custom-dropdown"
        size="lg"
      >
        <DropdownToggle caret>
          {selectedItem ? (
            <span>
              <img src={selectedItem.icon} alt="Icon" className="icon-image" />{" "}
              {selectedItem.title}
            </span>
          ) : (
            <span>
              <img src={iconImage} alt="Icon" className="icon-image" /> सेवा का
              चयन करें
            </span>
          )}
        </DropdownToggle>
        <DropdownMenu>
          {serviceItems.map((item, index) => (
            <DropdownItem key={index} onClick={() => handleItemClick(item)}>
              <img src={item.icon} alt="Icon" className="icon-image" />{" "}
              {item.title}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </FormGroup>
  );
};

const Booking = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 1000px)",
  });
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [userNote, setUserNote] = useState("");
  const [address, setAddress] = useState("");
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [priceTotal, setPriceTotal] = useState(0);
  const [paymentOption, setPaymentOption] = useState("payNow");

  const handlePaymentOptionChange = (e) => {
    setPaymentOption(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    // You can access the captured values with fullName, phone, and email state variables
    if (priceTotal === 0) {
      toast.error("Please select at least one service type");
      return;
    }
    if (selectedDateTime === null) {
      toast.error("Please select valid date & time");
      return;
    }
    if (!email || !fullName || !phone || !address) {
      toast.error("Please valid details like email/phone/full name/address");
      return;
    }
    toast.success("Thanks for booking! We will contact you soon!");
    // Configure SMTPJS with your email details and API key
    const Email = {
      SecureToken: "33c9d383-28d5-4e9e-b748-00aa7f3a581a",
      To: "info.shivaynamahom@gmail.com", // Replace with the recipient's email address
      From: "info.shivaynamahom@gmail.com", // Replace with your email address
      Subject: `New Booking: ${email}`,
      Body: `
      <!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Email Template</title>
  <style>
    body {
      font-family: Arial, sans-serif;
      color: #333333;
    }

    .container {
      max-width: 600px;
      margin: 0 auto;
      padding: 20px;
      background-color: #f8f8f8;
    }

    h1 {
      font-size: 24px;
      color: #333333;
      margin-top: 0;
    }

    p {
      margin-bottom: 10px;
    }

    .field-label {
      font-weight: bold;
    }
  </style>
</head>
<body>
  <div class="container">
    <h1>New Booking Details</h1>
    <p><span class="field-label">Please find a new booking details below,</span></p>
    <p><span class="field-label">Service Type:</span> ${
      selectedItems
        .map((item) => `${item.title} ${item.priceLabel}`)
        .join(",") ?? ""
    }</p>
    <p><span class="field-label">Full Name:</span> ${fullName}</p>
    <p><span class="field-label">Phone:</span> ${phone}</p>
    <p><span class="field-label">Email:</span> ${email}</p>
    <p><span class="field-label">User Note:</span> ${userNote}</p>
    <p><span class="field-label">Address:</span> ${address}</p>
    <p><span class="field-label">Selected Date & Time:</span> ${selectedDateTime}</p>
    <p><span class="field-label">Total Price:</span> ${priceTotal}</p>
    <p><span class="field-label">Payment Status:</span> ${
      paymentOption === "payNow" ? "Paid" : "Not Paid"
    }</p>
  </div>
</body>
</html>

    `,
    };

    const UserEmail = {
      SecureToken: "33c9d383-28d5-4e9e-b748-00aa7f3a581a",
      To: email, // Replace with the recipient's email address
      From: "info.shivaynamahom@gmail.com", // Replace with your email address
      Subject: `Booking Confirmation & Thank You`,
      Body: `
      <!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Email Template</title>
  <style>
    body {
      font-family: Arial, sans-serif;
      color: #333333;
    }

    .container {
      max-width: 600px;
      margin: 0 auto;
      padding: 20px;
      background-color: #f8f8f8;
    }

    h1 {
      font-size: 24px;
      color: #333333;
      margin-top: 0;
    }

    p {
      margin-bottom: 10px;
    }

    .footer {
      margin-top: 20px;
      font-size: 14px;
      color: #777777;
    }
  </style>
</head>
<body>
  <div class="container">
    <h1>Thank You for Booking</h1>
    
    <p>Thanks for booking! Our executives will connect with you shortly to confirm the details.</p>
    
    <div class="footer">
      <p>If you have any questions or need further assistance, please contact us at:</p>
      <p>Contact Number: +919098284046</p>
    </div>
  </div>
</body>
</html>
      `,
    };

    window.Email.send(Email)
      .then((message) => {
        console.log("Email sent successfully to admin:", message);
      })
      .catch((error) => {
        console.error("Error sending email to admin:", error);
      });

    window.Email.send(UserEmail)
      .then((message) => {
        console.log("Email sent successfully to user: ", message);
      })
      .catch((error) => {
        console.error("Error sending email to user:", error);
      });

    setFullName("");
    setPhone("");
    setUserNote("");
    setAddress("");
    setSelectedDateTime(null);
    setIsOpen(false);
    setSelectedItems([]);
    setPriceTotal(0);
    setPaymentOption("payNow");
    setEmail("");
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item) => {
    const index = selectedItems.indexOf(item);
    if (index === -1) {
      setSelectedItems([...selectedItems, item]);
    } else {
      setSelectedItems([
        ...selectedItems.slice(0, index),
        ...selectedItems.slice(index + 1),
      ]);
    }
  };

  useEffect(() => {
    // Calculate the total price whenever selectedItems change
    setPriceTotal(selectedItems.reduce((acc, item) => acc + item.price, 0));
  }, [selectedItems]);

  const handleDateTimeChange = (date) => {
    setSelectedDateTime(date);
  };

  const handlePhoneChange = (e) => {
    const phoneNumber = e.target.value;
    // Remove any non-digit characters from the phone number
    const cleanPhoneNumber = phoneNumber.replace(/\D/g, "");
    setPhone(cleanPhoneNumber);
  };
  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value;
    setEmail(enteredEmail);

    // Email validation regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(enteredEmail)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };
  const handleFullNameChange = (e) => {
    const enteredFullName = e.target.value;
    // Validate that the full name only contains characters (letters and spaces)
    const fullNamePattern = /^[A-Za-z\s]+$/;
    if (fullNamePattern.test(enteredFullName)) {
      setFullName(enteredFullName);
    }
  };
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://smtpjs.com/v3/smtp.js";
    script.async = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <Container className="py-5" id="Booking">
      <ToastContainer />
      <Helmet>
        <script src="https://smtpjs.com/v3/smtp.js" async />
      </Helmet>
      <div className="App offset-md-2 col-sm-12 col-md-8 col-lg-8">
        <h4 className="text-center fw-bold text-indian-saffron mb-4">
          अभी बुक करने के लिए विवरण दर्ज करें
        </h4>
        <Form className="form">
          <Row>
            <Col md="6">
              <ServiceDropdown />
            </Col>

            <Col md="6">
              <FormGroup>
                <Label for="datepicker">
                  <b>सेवा प्रकार का चयन करें</b>
                  <span style={{ fontSize: "12px" }}>
                    (आप एक से अधिक सेवाओं का चयन कर सकते हैं)
                  </span>
                </Label>
                <Dropdown isOpen={isOpen} toggle={toggleDropdown} size="lg">
                  <DropdownToggle caret>सेवा प्रकार चुनें</DropdownToggle>
                  <DropdownMenu>
                    {serviceTypeItems.map((item, index) => (
                      <DropdownItem
                        key={item.title}
                        active={selectedItems.includes(item)}
                        onClick={() => handleItemClick(item)}
                      >
                        {item.title}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="datepicker">
                  <b>दिनांक और पसंदीदा समय चुनें </b>{" "}
                  <FontAwesomeIcon
                    icon={faCalendarAlt}
                    className="calendar-icon"
                  />
                </Label>
                <DatePicker
                  id="date-picker"
                  selected={selectedDateTime}
                  onChange={handleDateTimeChange}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                  className="form-control-lg form-control"
                  minDate={new Date()}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="fullName">
                  <b>पूरा नाम</b>
                </Label>
                <Input
                  rows="4"
                  cols="50"
                  bsSize="lg"
                  type="text"
                  name="fullName"
                  id="fullName"
                  placeholder="अपना पूरा नाम भरें"
                  value={fullName}
                  onChange={handleFullNameChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="phone">
                  <b>फ़ोन</b>
                </Label>
                <Input
                  rows="4"
                  cols="50"
                  bsSize="lg"
                  type="tel"
                  name="phone"
                  id="phone"
                  placeholder="अपना फ़ोन दर्ज करें"
                  value={phone}
                  onChange={handlePhoneChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="email">
                  <b>ईमेल</b>
                </Label>
                <Input
                  rows="4"
                  cols="50"
                  bsSize="lg"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="अपना ईमेल पता दर्ज करें"
                  value={email}
                  onChange={handleEmailChange}
                  invalid={emailError !== ""}
                  required
                />
                {emailError && (
                  <div className="invalid-feedback">{emailError}</div>
                )}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="address">
                  <b>पता</b>
                </Label>
                <Input
                  rows="4"
                  cols="50"
                  bsSize="lg"
                  type="text"
                  name="address"
                  id="address"
                  placeholder="अपना पता दर्ज करें"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup>
                <Label for="userNote">
                  <b>अतिरिक्त नोट</b>
                </Label>
                <Input
                  rows="4"
                  cols="50"
                  bsSize="lg"
                  type="textarea"
                  name="userNote"
                  id="userNote"
                  placeholder="अपने नोट्स दर्ज करें"
                  value={userNote}
                  onChange={(e) => setUserNote(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="totalPrice">
                  <b>कुल कीमत</b>(
                  <img
                    src={rupeePng}
                    alt="Payment"
                    style={{ width: "10px", height: "14px" }}
                  />
                  )
                </Label>
                <Input
                  rows="4"
                  cols="50"
                  bsSize="lg"
                  type="number"
                  name="totalPrice"
                  id="totalPrice"
                  style={{ fontWeight: "bold" }}
                  autoComplete="no-cache"
                  value={priceTotal}
                  readOnly
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="paymentOption">
                  <b>भुगतान विकल्प</b>
                </Label>
                <Input
                  rows="4"
                  cols="50"
                  bsSize="lg"
                  type="select"
                  name="paymentOption"
                  id="paymentOption"
                  onChange={handlePaymentOptionChange}
                >
                  <option value="payNow">अभी भुगतान करें</option>
                  <option value="payLater">बाद में भुगतान करें</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>

          {paymentOption === "payNow" && (
            <div className="text-center mt-4">
              <FormGroup>
                <img
                  src={qrCode}
                  style={{ width: "150px", height: "150px" }}
                  alt="Payment QR Code"
                />
              </FormGroup>
            </div>
          )}
          <div className="text-center mt-4">
            <Button
              color="link"
              className="p-0 text-decoration-none"
              onClick={handleSubmit}
            >
              <span className="bookbuttonbig">अभी बुक करें</span>
            </Button>
          </div>
        </Form>
      </div>
      {/* <Row className="my-5">
        <Col sm={12} md={12} lg={12}>
          <p className="text-center fw-bold text-orange">आयोजन</p>
          <h1 className="text-center fw-bolder mb-5">
            <b>
              हमसे जुड़ें और <br />
              कुछ महान का हिस्सा बनें
            </b>
          </h1>
          <div
            style={{ backgroundImage: `url(${ev1})` }}
            className="position-relative"
          >
            <div
              className="event-det"
              style={{ width: isMobile ? "100%" : "" }}
            >
              <p className="text-light mb-4">
                <b>आगामी घटना</b>
              </p>
              <h4 className="mb-4">हमारे उपदेशों को देखें और सुनें</h4>
              <p className="mb-4">
                ग्राहक के लिए कोचिंग प्रक्रिया का पालन करना बहुत महत्वपूर्ण है,
                लेकिन इस बार मैं इसे दूंगा।
              </p>
              <p>
                <b>शुक्रवार 23:39 IST शनिवार 11:20 आईएसडी</b>
              </p>
              <p>
                <b>नंबर 233 मेन सेंट न्यूयॉर्क, संयुक्त राज्य अमेरिका</b>
              </p>
              <Button className="btn-gradient bg-white text-secondary mt-4">
                <b> अभी जाएँ!</b>
              </Button>
            </div>
          </div>
        </Col>
      </Row> */}
    </Container>
  );
};

export default Booking;
