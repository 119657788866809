import React, { useState } from "react";
import { BsList } from "react-icons/bs";
import { useMediaQuery } from "react-responsive";
import logo from "../../../../assets/images/om-logo.png";
import {
  Navbar,
  NavbarBrand,
  Container,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap";

const Header = () => {
  const logoStyle = {
    width: "76px",
    height: "76px",
  };
  const [isSideOpen, setSideOpen] = useState(false);
  const menus = ["Home", "Services", "About us", "Booking", "Contact Us"];
  const menuHindi = {
    Home: "मुख्य पेज",
    Services: "सेवाएं",
    "About us": "हमारे बारे में",
    Booking: "बुकिंग",
    "Contact Us": "संपर्क करें",
  };

  const toggleSidebar = () => {
    setSideOpen(!isSideOpen);
  };
  const showMenu = useMediaQuery({
    query: "(max-width: 1000px)",
  });
  return (
    <div>
      <Navbar>
        <Container className="d-flex justify-content-between align-items-center">
          <NavbarBrand href="/">
            <img src={logo} alt="" style={logoStyle} />
          </NavbarBrand>
          {showMenu ? (
            <Button
              color="link"
              onClick={() => setSideOpen(true)}
              style={{ fontSize: "20px", color: "#000" }}
            >
              <BsList />
            </Button>
          ) : (
            <div>
              {menus.map((menu) => (
                <a
                  href={`#${menu}`}
                  className="menu text-decoration-none text-dark"
                  key={menu}
                >
                  {menuHindi[menu]}
                </a>
                // <span className="menu">{menu}</span>
              ))}
            </div>
          )}
        </Container>
      </Navbar>

      <div>
        <Offcanvas toggle={toggleSidebar} isOpen={isSideOpen} direction="end">
          <OffcanvasHeader toggle={toggleSidebar}>
            <img src={logo} alt="" width={150} />
          </OffcanvasHeader>
          <OffcanvasBody>
            <ul>
              {menus.map((menu) => (
                <li
                  className="menu m-0 mb-4"
                  key={menu}
                  onClick={() => toggleSidebar()}
                >
                  <a
                    href={`#${menu}`}
                    className="text-dark text-decoration-none"
                  >
                    {menuHindi[menu]}
                  </a>
                </li>
              ))}
            </ul>
          </OffcanvasBody>
        </Offcanvas>
      </div>
    </div>
  );
};

export default Header;
