export const SUBSCRIPTION_EMAIL_TEMPLATE = `
<!DOCTYPE html>
  <html>
  <head>
    <style>
      body {
        font-family: Arial, sans-serif;
      }
      
      .container {
        max-width: 600px;
        margin: 0 auto;
        padding: 20px;
        background-color: #f5f5f5;
      }
      
      h2 {
        color: #333;
      }
      
      p {
        margin-bottom: 20px;
        line-height: 1.5;
      }
      
      .btn {
        display: inline-block;
        padding: 10px 20px;
        background-color: #4CAF50;
        color: #fff;
        text-decoration: none;
        border-radius: 3px;
      }
      
      .btn:hover {
        background-color: #45a049;
      }
    </style>
  </head>
  <body>
    <div class="container">
      <h2>Newsletter Subscription Request</h2>
      <p>Hello Admin,</p>
      <p>A user has requested to subscribe to the weekly newsletter. Please take the necessary actions to process the request.</p>
      
      <p><strong>User Email:</strong> %SUBSCRIPTION_EMAIL_ADDRESS%</p>
      
      <p>To confirm the subscription, click the button below:</p>
      <a href="#" class="btn">Confirm Subscription</a>
      
      <p>If you have any questions or need further information, please feel free to contact us.</p>
      
      <p>Thank you!</p>
      <p>Your Website Team</p>
    </div>
  </body>
  </html>
  `;