import React from "react";
import Home from "./pages/Home";
import "./style.css";
import { Spinner } from "reactstrap";
import { useState } from "react";
import { useEffect } from "react";

function App() {
  const [spinner, setSpinner] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setSpinner(false);
    }, 1000);
  }, []);

  return (
    <React.Fragment>
      {spinner ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <Spinner size="lg" />
        </div>
      ) : (
        <Home />
      )}
    </React.Fragment>
  );
}

export default App;
