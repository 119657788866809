import React from "react";
import Flickity from "react-flickity-component";
import slide001 from "../../../../assets/images/slides/001.jpg";
import slide004 from "../../../../assets/images/slides/004.jpg";
import slide006 from "../../../../assets/images/slides/006.jpg";
import slide008 from "../../../../assets/images/slides/008.jpg";
import slide015 from "../../../../assets/images/slides/015.jpg";
import slide010 from "../../../../assets/images/slides/010.jpg";
import slide011 from "../../../../assets/images/slides/011.jpg";
import slide014 from "../../../../assets/images/slides/014.jpg";
import slide013 from "../../../../assets/images/slides/013.jpg";
import slide016 from "../../../../assets/images/slides/016.jpg";
import slide017 from "../../../../assets/images/slides/017.jpg";
import slide018 from "../../../../assets/images/slides/018.jpg";
import slide019 from "../../../../assets/images/slides/019.jpg";
import slide020 from "../../../../assets/images/slides/020.jpg";
import slide021 from "../../../../assets/images/slides/021.jpg";
import slide022 from "../../../../assets/images/slides/022.jpg";
import slide023 from "../../../../assets/images/slides/023.jpg";
import slide024 from "../../../../assets/images/slides/024.jpg";
import slide025 from "../../../../assets/images/slides/025.jpg";
import slide027 from "../../../../assets/images/slides/027.jpg";
import slide026 from "../../../../assets/images/slides/026.jpg";

// const flickityOptions = {
//   initialIndex: 2,
// };
const Slider = () => {
  const slide = [
    slide001,
    slide004,
    slide006,
    slide027,
    slide008,
    slide010,
    slide011,
    slide013,
    slide014,
    slide015,
    slide016,
    slide017,
    slide018,
    slide019,
    slide020,
    slide021,
    slide022,
    slide023,
    slide024,
    slide025,
    slide026,
  ];
  return (
    <div id="Home">
      <Flickity
        // className={"carousel"} // default ''
        elementType={"div"} // default 'div'
        options={{
          autoPlay: true,
          pauseAutoPlayOnHover: true,
          cellAlign: "center",
          wrapAround: true,
          adaptiveHeight: true,
        }}
        // options={flickityOptions} // takes flickity options {}
        // disableImagesL oaded={false} // default false
        // reloadOnUpdate // default false
        // static // default false
      >
        {slide &&
          slide.map((slide) => (
            <img
              src={slide}
              alt="Slide"
              style={{ width: "1499px", height: "760px" }}
            />
          ))}
      </Flickity>
    </div>
  );
};

export default Slider;
