import React from "react";
import { Col, Container, Row } from "reactstrap";
import event1 from "../../../../assets/images/event-1.png";
import event2 from "../../../../assets/images/event-2.png";
import event3 from "../../../../assets/images/event-3.png";
import event4 from "../../../../assets/images/event-4.png";

const Watch = () => {
  return (
    <div style={{ background: "#f5f2f0" }} className="py-5">
      <Container>
        {/* <Row>
          <Col sm={12} md={8} lg={8} className="text-center offset-md-2">
            <p className="text-center fw-bold text-orange">WATCH AND LISTEN</p>
            <h1 className="text-center fw-bolder mb-4">
              THE BENEFITS OF JOINING <br />
              OUR RECENT EVENT
            </h1>
          </Col>
        </Row>
        <Row className="my-4">
          <Col sm={12} md={3} lg={3} className="mb-4 ">
            <div className="event-s position-relative">
              <img src={event1} alt="" className="w-100 rounded" />
              <h3 className="feature-bottom">
                Watch and listen to our sermons
              </h3>
            </div>
          </Col>
          <Col sm={12} md={3} lg={3} className="mb-4 ">
            <div className="event-s position-relative">
              <img src={event2} alt="" className="w-100 rounded" />
              <h3 className="feature-bottom">
                Watch and listen to our sermons
              </h3>
            </div>
          </Col>
          <Col sm={12} md={3} lg={3} className="mb-4 ">
            <div className="event-s position-relative">
              <img src={event3} alt="" className="w-100 rounded" />
              <h3 className="feature-bottom">
                Watch and listen to our sermons
              </h3>
            </div>
          </Col>
          <Col sm={12} md={3} lg={3} className="mb-4 ">
            <div className="event-s position-relative">
              <img src={event4} alt="" className="w-100 rounded" />
              <h3 className="feature-bottom">
                Watch and listen to our sermons
              </h3>
            </div>
          </Col>
        </Row> */}
      </Container>
    </div>
  );
};

export default Watch;
