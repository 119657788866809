import React from "react";
import Slider from "./components/Slider";
import Header from "./components/Header";
import Services from "./components/Services";
import Prayer from "./components/Prayer";
import Watch from "./components/Watch";
import Booking from "./components/Booking";
import Footer from "./components/Footer";
import Contact from "./components/Contact";

const Home = () => {
  return (
    <div>
      <Header />
      <div className="app-height">
        <Slider />
        <Services />
        <Prayer />
        <Watch />
        <Booking />
        <Contact />
        <Footer />
      </div>
    </div>
  );
};

export default Home;
