import React, { useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import prayer1 from "../../../../assets/images/praying1.jpg";
import prayer2 from "../../../../assets/images/praying2.jpg";
import prayer3 from "../../../../assets/images/praying3.jpg";

const Prayer = () => {
  const [isShowMore, setIsShowMore] = useState(false);

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };
  return (
    <Container className="py-5" id="About us">
      <Row className="mb-4">
        <Col sm={12} md={8} lg={8} className="text-center offset-md-2">
          <p className="text-center fw-bold text-orange">SHIVAY NAMAH OM</p>
          <h1 className="text-center fw-bolder mb-4">
            पवित्र अनुष्ठानों के साथ आध्यात्मिक आनंद की यात्रा करें
          </h1>
          <p className="mb-4">
            गहन आध्यात्मिकता और समृद्ध हिंदू परंपराओं की दुनिया के लिए आपका
            प्रवेश द्वार शिवाय नमः ओम में आपका स्वागत है। शिवाय नमः ओम में, हम
            आपको भक्ति की गहराई का पता लगाने और हमारे प्रामाणिक अनुष्ठानों,
            पूजा, होम/यज्ञ, मंदिर दर्शन और अन्य आध्यात्मिक सेवाओं के माध्यम से
            सर्वोच्च चेतना से जुड़ने के लिए आमंत्रित करते हैं। भगवान शिव की
            शिक्षाओं की गहरी समझ विकसित करने, आध्यात्मिक प्रथाओं को अपनाने और
            परमात्मा के साथ गहन मिलन का अनुभव करने में आपकी मदद करने के लिए
            हमारी पेशकश सावधानीपूर्वक तैयार की गई है।
          </p>
          {isShowMore && (
            <div>
              <p className="mb-4">
                हमारा मंच दिव्य मंत्र "शिवाय नमः ओम" से प्रेरित है, जो भगवान शिव
                की अनंत शक्ति, परोपकार और ब्रह्मांडीय ऊर्जा के सार से गूंजता है।
              </p>
              <p>
                हमारी सेवाओं के माध्यम से, हम आपकी आत्मा को प्रज्वलित करना,
                दिव्य आशीर्वादों का आह्वान करना और आध्यात्मिक जागृति की ओर आपका
                मार्गदर्शन करना चाहते हैं।
              </p>
              <p className="mb-4">
                चाहे आप पवित्र अनुष्ठानों में भाग लेना चाहते हों, पूजा और होम /
                यज्ञों के माध्यम से आशीर्वाद प्राप्त करना चाहते हों, या हमारी
                मंदिर दर्शन सेवा के साथ श्रद्धेय मंदिरों की आभासी यात्रा शुरू
                करना चाहते हों, शिवाय नमः ओम भक्ति और आध्यात्मिक विकास के पथ पर
                आपका साथी है।
              </p>
              <p className="mb-4">
                हमारे साथ जुड़ें क्योंकि हम भगवान शिव का सम्मान करते हैं, ओम के
                पवित्र स्पंदनों को ग्रहण करते हैं, और भक्ति की शाश्वत लौ को अपने
                भीतर पोषित करते हैं। शिवाय नमः ओम में, हम एक अभयारण्य प्रदान
                करने के लिए समर्पित हैं जहां आप भगवान शिव के साथ अपने संबंध को
                गहरा कर सकते हैं, आंतरिक शांति की खोज कर सकते हैं और दिव्य
                अनुग्रह की परिवर्तनकारी शक्ति का अनुभव कर सकते हैं।
              </p>
            </div>
          )}
          {/* <Button className="btn-gradient">Read More</Button> */}
          <Button onClick={toggleReadMoreLess} className="btn-gradient">
            {isShowMore ? "कम पढ़ें" : "और पढ़ें"}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={4} lg={4}>
          <img src={prayer1} alt="" className="w-100 rounded" />
        </Col>
        <Col sm={12} md={4} lg={4}>
          <img src={prayer2} alt="" className="w-100 mt-5 rounded mb-4" />
        </Col>
        <Col sm={12} md={4} lg={4}>
          <img src={prayer3} alt="" className="w-100 rounded" />
        </Col>
      </Row>
      <Row className="mt-5">
        <Col sm={12} md={8} lg={8} className="text-center offset-md-2">
          <p className="text-center fw-bold text-orange">
          हमारा मिशन और विजन
          </p>
          {/* <h1 className="text-center fw-bolder mb-4">
            <b>Mission</b>
          </h1> */}
          <p className="mb-4">
            शिवाय नमः ओम पर हमारा मिशन व्यक्तियों को गहन आध्यात्मिकता और समृद्ध
            हिंदू परंपराओं का पता लगाने के लिए एक पवित्र ऑनलाइन अभयारण्य प्रदान
            करना है। हम प्रामाणिक अनुष्ठान, पूजा, होम/यज्ञ, मंदिर दर्शन, और
            आध्यात्मिक सेवाएं प्रदान करते हैं ताकि आत्माओं को प्रज्वलित किया जा
            सके, दिव्य आशीर्वाद प्राप्त किया जा सके, और आध्यात्मिक जागृति और
            विकास के लिए व्यक्तियों का मार्गदर्शन किया जा सके।
          </p>
          <p className="mb-4">
            शिवाय नमः ओम पर, हमारी दृष्टि सर्वोच्च चेतना से जुड़ने के इच्छुक
            व्यक्तियों के लिए अग्रणी ऑनलाइन गंतव्य बनना है। हमारा उद्देश्य लोगों
            को उनकी भक्ति को गले लगाने, पवित्र अनुष्ठानों में भाग लेने, आशीर्वाद
            लेने और श्रद्धेय मंदिरों की आभासी यात्रा शुरू करने के लिए सशक्त
            बनाना है। हम एक वैश्विक समुदाय को बढ़ावा देने की इच्छा रखते हैं जो
            भगवान शिव का सम्मान करता है, ओम के पवित्र स्पंदनों को गले लगाता है,
            और परमात्मा के साथ गहरा संबंध रखता है।
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Prayer;
