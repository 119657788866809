import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

const Contact = () => {
  return (
    <div style={{ background: "#f5f2f0" }} className="pt-5" id="Contact Us">
      <Container className="offset-md-2 col-sm-12 col-md-8 col-lg-8">
        <h4 className="text-center fw-bold text-orange mb-4">संपर्क करें</h4>
        <p className="mb-4">
          संपर्क करें हम आपकी प्रतिक्रिया को महत्व देते हैं और आपसे सुनना पसंद
          करेंगे। चाहे आपका कोई प्रश्न हो, सुझाव हो या सहायता की आवश्यकता हो,
          हमारी टीम सहायता के लिए तैयार है। कृपया नीचे दिए गए किसी भी तरीके का
          उपयोग करके हमसे बेझिझक संपर्क करें:
        </p>
        <div className="d-flex justify-content-between">
          <p className="mb-4">
            <b>फ़ोन: +919098284046</b>
          </p>
          <p className="mb-4">
            <b>ईमेल: info.shivaynamahom@gmail.com</b>
          </p>
        </div>
        <Row>
          <Col md="6">
            <FormGroup>
              <Label for="fullName">
                <b>नाम</b>
              </Label>
              <Input
                rows="4"
                cols="50"
                bsSize="lg"
                type="text"
                name="fullName"
                id="fullName"
                placeholder="अपना पूरा नाम भरें"
                required
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label for="phone">
                <b>फ़ोन</b>
              </Label>
              <Input
                rows="4"
                cols="50"
                bsSize="lg"
                type="tel"
                name="phone"
                id="phone"
                placeholder="अपना फोन नंबर डालें"
                required
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label for="email">
                <b>ईमेल</b>
              </Label>
              <Input
                rows="4"
                cols="50"
                bsSize="lg"
                type="email"
                name="email"
                id="email"
                placeholder="अपना ईमेल पता दर्ज करें"
                required
              />
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup>
              <Label for="address">
                <b>संदेश</b>
              </Label>
              <Input
                rows="4"
                cols="50"
                bsSize="lg"
                type="textarea"
                name="message"
                id="message"
                placeholder="संदेश"
                required
              />
            </FormGroup>
          </Col>
          <div className="text-center mt-4">
            <Button className="btn-gradient">संपर्क करें</Button>
          </div>
        </Row>
      </Container>
    </div>
  );
};
export default Contact;
