import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import fav from "../../../../assets/images/om-logo.png";
import { ToastContainer, toast } from "react-toastify";
import { SUBSCRIPTION_EMAIL_TEMPLATE } from "../../constants/constants";
const Footer = () => {
  const logoStyle = {
    width: "116px",
    height: "116px",
  };
  const [email, setEmail] = useState("");
  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value;
    setEmail(enteredEmail);
  };
  const handleSubscription = () => {
    // Email validation regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      return toast.error("Please enter a valid email address");
    }
    toast.success("Thanks for subscribing to our newsletter!");
    const Email = {
      SecureToken: "33c9d383-28d5-4e9e-b748-00aa7f3a581a",
      To: "info.shivaynamahom@gmail.com",
      From: "info.shivaynamahom@gmail.com",
      Subject: `Newsletter Subscription Request: ${email}`,
      Body: SUBSCRIPTION_EMAIL_TEMPLATE.replace(
        "%SUBSCRIPTION_EMAIL_ADDRESS%",
        email
      ),
    };

    window.Email.send(Email)
      .then((message) => {
        console.log("Email sent successfully to admin:", message);
      })
      .catch((error) => {
        console.error("Error sending email to admin:", error);
      });
  };
  return (
    <div className="footer-min pt-5">
      <Container>
        <ToastContainer />
        <Row>
          <Col sm={12} md={3} lg={3}>
            <h3 className="text-white mb-4">हमारे बारे में</h3>
            <img src={fav} alt="" style={logoStyle} />
            <p className="text-white">
              <b>शिवाय नमः ॐ</b>
            </p>
            <p className="text-white">
              पवित्र अनुष्ठानों के साथ आध्यात्मिक आनंद की यात्रा करें
            </p>
          </Col>
          <Col sm={12} md={3} lg={3}>
            <h3 className="text-white mb-4">जानकारी</h3>
            <ul>
              <li className="text-white pb-3 text-decoration-none">
                हमारे बारे में
              </li>
              <li className="text-white pb-3 text-decoration-none">
                हमारी टीम से मिलें
              </li>
              <li className="text-white pb-3 text-decoration-none">
                हमारा पोर्टफ़ोलियो
              </li>
              <li className="text-white pb-3 text-decoration-none">ताजा खबर</li>
              <li className="text-white pb-3 text-decoration-none">संपर्क</li>
            </ul>
          </Col>
          <Col sm={12} md={3} lg={3}>
            <h3 className="text-white mb-4">संपर्क</h3>
            <ul>
              <li className="text-white pb-3 text-decoration-none">
                <b>फ़ोन: +919098284046</b>
              </li>
              <li className="text-white pb-3 text-decoration-none">
                <b>ईमेल: info.shivaynamahom@gmail.com</b>
              </li>
            </ul>
          </Col>
          <Col sm={12} md={3} lg={3}>
            <h5 className="text-white mb-4">
            हमारी मेलिंग सूची में शामिल हों और सीधे अपने इनबॉक्स में दिव्य ज्ञान प्राप्त करें!
            </h5>
            <FormGroup>
              <Label for="email">
                <b>Email</b>
              </Label>
              <Input
                rows="4"
                cols="50"
                bsSize="lg"
                type="email"
                name="email"
                id="email"
                placeholder="अपना ईमेल पता दर्ज करें"
                value={email}
                onChange={handleEmailChange}
                required
              />
            </FormGroup>
            <Button
              className="btn-gradient mt-4 block"
              onClick={handleSubscription}
            >
              सदस्यता लें
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Footer;
